import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    GRUPOLOCACAO: 'Grupo Locação'
}

export default [
    {
        name: ROUTER_NAMES.GRUPOLOCACAO,
        path: '/grupo-locacao',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Grupo Locação',
            searchDescription: 'Veja todos os grupos de locação',
            searchTerms: ['grupo','locação'],
            hasRole: 'role/grupoLocacao'
        },
        component: () => import('@/views/GrupoLocacao')
    },
]