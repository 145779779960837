import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    TURMASS: 'Turmas'
}

export default [
    {
        name: ROUTER_NAMES.TURMASS,
        path: '/quadro-de-aulas',
        meta: {
            title: 'Quadro de Aulas',
            menu: MENUS.CADASTRO,
            searchAlias: 'Quadro de Aulas',
            searchDescription: 'Veja o quadro de aulas',
            searchTerms: ['Quadro','Aulas'],
            hasRole: 'role/turmas'
        },
        component: () => import('@/views/Turmas')
    },
]