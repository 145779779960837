import aluguel from "./modulos/aluguel";
import matriculas from "./modulos/matriculas";
import frequencia from "./modulos/frequencia";
import tipoTurma from "./modulos/tipoTurma";
import Vue from 'vue'
import VueRouter from 'vue-router'
import locais from "./modulos/locais";
import turmas from "./modulos/turmas";
import horarios from "./modulos/horarios";
import quadras from "./modulos/quadras";
import professores from "./modulos/professores";
import locacoes from "./modulos/locacoes";
import cursos from "./modulos/cursos";
import clientes from "./modulos/clientes";
import aulas from './modulos/aulas';
import relatorios from './modulos/relatorios';
import store from '../store';
import alterarSenha from "./modulos/alterarSenha";
import parametros from "./modulos/parametros";
import funcionarios from "./modulos/funcionarios";
import mensagens from "./modulos/mensagens";
import areaCliente from "./modulos/areaCliente";
import painel from "./modulos/painel";
import eventos from "./modulos/eventos";
import etapas from "./modulos/etapas";
import competicoes from "./modulos/competicoes";
import inscricoes from "./modulos/inscricoes";
import faturas from "./modulos/faturas";
import grupoLocacao from "./modulos/grupoLocacao";

Vue.use(VueRouter)

const routes = [
    ...aluguel,
    ...matriculas,
    ...frequencia,
    ...tipoTurma,
    ...turmas,
    ...horarios,
    ...quadras,
    ...professores,
    ...locais,
    ...locacoes,
    ...cursos,
    ...clientes,
    ...aulas,
    ...relatorios,
    ...alterarSenha,
    ...parametros,
    ...funcionarios,
    ...mensagens,
    ...areaCliente,
    ...painel,
    ...eventos,
    ...etapas,
    ...competicoes,
    ...inscricoes,
    ...faturas,
    ...grupoLocacao,
    {
        name: 'LoginCliente',
        path: '/login/cliente',
        props: {
            type: 'cliente'
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        meta: {
            title: 'Sem Autorização',
            unauthorized: true,
        },
        component: () => import('@/views/Unauthorized')
    },
    {
        name: 'LoginFuncionario',
        path: '/login/funcionario',
        props: {
            type: 'funcionario'
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },
    {
        name: 'Cadastre-se',
        path: '/cadastre-se',
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Cadastrese')
    },
    {
        name: 'InscricaoCopaFuturo',
        path: '/copa-futuro',
        props: {
            idCompEvento: 2
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Inscrevase')
    },
    {
        name: 'InscricaoCircuitoAmizade',
        path: '/circuito-amizade',
        props: {
            idCompEvento: 1
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Inscrevase')
    },
    {
        name: 'OutrosEventos',
        path: '/outros-eventos',
        props: {
            idCompEvento: 0
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Inscrevase')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored;
    const {meta} = to;

    function isGoingToLoginPage() {
        return meta.hasOwnProperty('loginPage') && meta.loginPage;
    }

    function isGoingToUnauthorizedPage() {
        return meta.hasOwnProperty('unauthorized') && meta.unauthorized;
    }

    async function handleIsAuthenticated() {
        const defaultHomePage = await store.dispatch('auth/getDefaultHomePage');
        if (isGoingToLoginPage()) {
            next({
                name: defaultHomePage
            });
        } else {
            const authorizedLinks = [...store.getters['auth/authorizedLinks']];
            if (isGoingToUnauthorizedPage()) {
                next();
            } else {
                if (authorizedLinks.indexOf(to.path) > -1) {
                    next();
                } else {
                    if (to.path === '/') {
                        next({
                            name: defaultHomePage
                        })
                    } else {
                        next('/unauthorized')
                    }
                }
            }
        }
    }

    async function handleNotAuthenticated() {
        if (isGoingToLoginPage()) {
            next();
        } else {
            const defaultLoginPage = await store.dispatch('auth/getDefaultLoginPage');
            next({
                name: defaultLoginPage
            })
        }
    }

    if (store.getters['auth/isAuthenticated']) {
        await handleIsAuthenticated();
    } else {
        await handleNotAuthenticated();
    }

})

export default router
