import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios';
import './plugins/validators';
import './plugins/toastification';
import './plugins/async-computed';
import './plugins/router-sync';
import './plugins/mask';
import './plugins/moment';
import './plugins/sweet-alert';
import './plugins/utils';
import './plugins/shortkey';
import * as moment from 'moment';

Vue.mixin({
  methods: {
    formatDate(date, format) {
      return moment(date).format(format);
    }
  }
});

Vue.config.productionTip = process.env.NODE_ENV === 'production';

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
