import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    FATURAS: 'Faturas',
    GERAR_BOL_INTEGRA: 'Gerar Boletos Integração',
    GERAR_REMESSA: 'Gerar Arquivo Remessa',
    ARQUIVO_RETORNO: 'Processar Arquivo Retorno',
    LIXEIRA: 'Lixeira',
    LISTA_ARQUIVOS_REMESSA: 'Lista Arquivos Remessa',
}

export default [
    {
        name: ROUTER_NAMES.FATURAS,
        path: '/faturas',
        meta: {
            menu: MENUS.FINANCEIRO,
            searchAlias: 'Faturas',
            searchDescription: 'Veja todas as Faturas cadastradas',
            searchTerms: ['faturas'],
            hasRole: 'role/faturas'
        },
        component: () => import('@/views/Faturas/Lista')
    },
    {
        name: ROUTER_NAMES.GERAR_BOL_INTEGRA,
        path: '/gerar-integracao',
        meta: {
            menu: MENUS.FINANCEIRO,
            searchAlias: 'Remessa',
            searchDescription: 'Gerar Boletos Integração',
            searchTerms: ['faturas','boleto','arquivo'],
            hasRole: 'role/gerarIntegracao'
        },
        component: () => import('@/views/Faturas/GerarIntegracao')
    },
    {
        name: ROUTER_NAMES.GERAR_REMESSA,
        path: '/gerar-remessa',
        meta: {
            menu: MENUS.FINANCEIRO,
            searchAlias: 'Remessa',
            searchDescription: 'gerar arquivo remessa',
            searchTerms: ['faturas','remessa','arquivo'],
            hasRole: 'role/gerarRemessa'
        },
        component: () => import('@/views/Faturas/GerarRemessa')
    },
    {
        name: ROUTER_NAMES.ARQUIVO_RETORNO,
        path: '/processar-retorno',
        meta: {
            menu: MENUS.FINANCEIRO,
            searchAlias: 'Arquivo Retorno',
            searchDescription: 'Processar Arquivo Retorno',
            searchTerms: ['faturas','retorno','arquivo'],
            hasRole: 'role/processarRetorno'
        },
        component: () => import('@/views/Faturas/ProcessarRetorno')
    },
    {
        name: ROUTER_NAMES.LIXEIRA,
        path: '/lixeira',
        meta: {
            menu: MENUS.FINANCEIRO,
            searchAlias: 'Lixeira',
            searchDescription: 'Lixeira de faturas',
            searchTerms: ['faturas','Lixeira'],
            hasRole: 'role/lixeira'
        },
        component: () => import('@/views/Faturas/Lixeira')
    },
    {
        name: ROUTER_NAMES.LISTA_ARQUIVOS_REMESSA,
        path: '/arquivos-remessa',
        meta: {
            menu: MENUS.FINANCEIRO,
            searchAlias: 'Arquivos Remessa',
            searchDescription: 'Lista arquivos remessa',
            searchTerms: ['remessa'],
            hasRole: 'role/arquivosRemessa'
        },
        component: () => import('@/views/Faturas/ArquivosRemessa')
    },
]