import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    TIPOTURMAS: 'TipoTurmas'
}

export default [
    {
        name: ROUTER_NAMES.TIPOTURMAS,
        path: '/tipos-de-turmas',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Tipos de Curso',
            searchAlias: 'Tipos de Curso',
            searchDescription: 'Veja todas os tipos de turmas cadastrados',
            searchTerms: ['Curso','Tipo'],
            hasRole: 'role/tipoTurma'
        },
        component: () => import('@/views/TipoTurmas')
    },
]