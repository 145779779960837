import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    AULAS: 'Aulas'
}

export default [
    {
        name: ROUTER_NAMES.AULAS,
        path: '/aulas',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Aulas',
            searchDescription: 'Veja todas as aulas cadastradas',
            searchTerms: ['aulas'],
            hasRole: 'role/aulas'
        },
        component: () => import('@/views/Aulas')
    },
]