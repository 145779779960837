import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    MATRICULASS: 'Matriculas',
    AJUSTE_VALOR_MATRICULAS: 'Ajuste Valor Matrícula'
}

export default [
    {
        name: ROUTER_NAMES.MATRICULASS,
        path: '/matriculas',
        props: true,
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Matriculas',
            searchDescription: 'Veja todas as matriculass cadastradas',
            searchTerms: ['matriculas'],
            hasRole: 'role/matriculas'
        },
        component: () => import('@/views/Matriculas')
    },
    {
        name: ROUTER_NAMES.AJUSTE_VALOR_MATRICULAS,
        path: '/ajuste-valor-mensalidade',
        meta: {
            menu: MENUS.FINANCEIRO,
            searchAlias: 'Ajuste Mensalidades',
            searchDescription: 'Veja todas as Faturas cadastradas',
            searchTerms: ['ajuste','mensalidade'],
            hasRole: 'role/ajusteValorMensalidade'
        },
        component: () => import('@/views/Matriculas/AjusteValorMensalidade')
    },
]