import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    LOCACOES: 'Locações'
}

export default [
    {
        name: ROUTER_NAMES.LOCACOES,
        path: '/aluguel',
        meta: {
            menu: MENUS.FUNCIONALIDAES,
            searchAlias: 'Locações',
            searchDescription: 'Veja todos as Locações cadastradas',
            searchTerms: ['locação', 'Locações'],
            hasRole: 'role/aluguel'
        },
        component: () => import('@/views/Aluguels')
    },
]