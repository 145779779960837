"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findItemsDominio = exports.ItemDeDominio = void 0;
const core_1 = require("./core");
var ItemDeDominio;
(function (ItemDeDominio) {
    ItemDeDominio["NIVEL_ACESSO_USUARIO"] = "nivelAcessoUsuario";
    ItemDeDominio["ARR_FORMA_PGTO"] = "arrFormaPgto";
    ItemDeDominio["ARR_FORMA_PGTO_ABREV"] = "arrFormaPgtoAbrev";
    ItemDeDominio["ARR_DIAS_SEMANA"] = "arrDiasSemana";
    ItemDeDominio["ARR_MES"] = "arrMes";
    ItemDeDominio["STATUS_PADRAO"] = "statusPadrao";
    ItemDeDominio["TIPO_PLANO"] = "tipoPlano";
    ItemDeDominio["LOCAL_QUADRA"] = "localQuadra";
    ItemDeDominio["FLG_PADRAO"] = "flgPadrao";
    ItemDeDominio["ARR_SEXO"] = "arrSexo";
    ItemDeDominio["TIPO_ALUGUEL"] = "tipoAluguel";
    ItemDeDominio["TIPO_INSCRICAO"] = "tipoInscricao";
    ItemDeDominio["LOCAR_PARA"] = "locarPara";
    ItemDeDominio["STS_PRESENCA"] = "stsPresenca";
    ItemDeDominio["STS_PRESENCA_REL"] = "stsPresencaRel";
    ItemDeDominio["STS_PAGAMENTO"] = "stsPagamento";
    ItemDeDominio["COMBO_ESTADO"] = "comboEstado";
    ItemDeDominio["ARR_TIPO_MENSAGEM"] = "arrTipoMensagem";
    ItemDeDominio["ARR_TIPO_CURSO"] = "arrTipoCurso";
    ItemDeDominio["ARR_TIPO_FATURA"] = "arrTipoFatura";
    ItemDeDominio["ARR_DATA_FILTRO_RECB"] = "arrDataFiltroReceb";
    ItemDeDominio["STATUS_MENSAGEM"] = "arrStatusMensagem";
    ItemDeDominio["TIPO_ENVIO_MENSAGEM"] = "tipoEnvioMensagem";
    //tabelas de dominio
    ItemDeDominio["TABELA_TIPO_TURMA"] = "TipoTurma";
    ItemDeDominio["TABELA_TIPO_LOCACAO"] = "TipoLocacao";
    ItemDeDominio["TABELA_LOCAL"] = "Local";
    ItemDeDominio["TABELA_CURSO"] = "Curso";
})(ItemDeDominio = exports.ItemDeDominio || (exports.ItemDeDominio = {}));
function findItemsDominio(item) {
    return core_1.get('itemDominio', item)
        .then(({ dados }) => dados.map(item => ({
        key: item.key,
        valor: item.valor
    })));
}
exports.findItemsDominio = findItemsDominio;
