import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    MEU_CADASTRO: 'Cadastro',
    MINHAS_FREQUENCIAS: 'Minhas Frequências',
    MINHAS_MATRICULAS: 'Minhas Matrículas',
    MINHAS_FATURAS: 'Minhas Faturas',
    MINHAS_INSCRICOES: 'Minhas Inscrições',
    MINHAS_LOCACOES: 'Minhas Locações',
}

export default [
    {
        name: ROUTER_NAMES.MEU_CADASTRO,
        path: '/meu-cadastro',
        meta: {
            menu: MENUS.AREA_CLIENTE,
            title: 'Meus Dados',
            searchAlias: 'Meus Dados',
            searchDescription: 'Mantenha seus dados e de seus dependentes atualizados',
            searchTerms: ['meus','dados','cliente'],
            hasRole: 'role/meuCadastro'
        },
        component: () => import('@/views/MeuCadastro')
    },
    {
        name: ROUTER_NAMES.MINHAS_FREQUENCIAS,
        path: '/minhas-frequencias',
        meta: {
            menu: MENUS.AREA_CLIENTE,
            title: 'Minhas Frequências',
            searchAlias: 'Minhas Frequências',
            searchDescription: 'Visualize as suas frequências',
            searchTerms: ['minhas','frequencias'],
            hasRole: 'role/minhasFrequencias'
        },
        component: () => import('@/views/MinhasFrequencias')
    },
    {
        name: ROUTER_NAMES.MINHAS_MATRICULAS,
        path: '/minhas-matriculas',
        meta: {
            menu: MENUS.AREA_CLIENTE,
            title: 'Minhas Matrículas',
            searchAlias: 'Minhas Matrículas',
            searchDescription: 'Visualize as suas Matrículas',
            searchTerms: ['minhas','matriculas'],
            hasRole: 'role/minhasMatriculas'
        },
        component: () => import('@/views/MinhasMatriculas')
    },
    {
        name: ROUTER_NAMES.MINHAS_LOCACOES,
        path: '/meus-alugueis',
        meta: {
            menu: MENUS.AREA_CLIENTE,
            title: 'Minhas Locações',
            searchAlias: 'Minhas Locações',
            searchDescription: 'Visualize as suas Locações',
            searchTerms: ['meus','alugueis'],
            hasRole: 'role/meusAlugueis'
        },
        component: () => import('@/views/MeusAlugueis')
    },
    {
        name: ROUTER_NAMES.MINHAS_FATURAS,
        path: '/minhas-faturas',
        meta: {
            menu: MENUS.AREA_CLIENTE,
            title: 'Minhas Faturas',
            searchAlias: 'Minhas Faturas',
            searchDescription: 'Visualize as suas Faturas',
            searchTerms: ['minhas','faturas'],
            hasRole: 'role/minhasFaturas'
        },
        component: () => import('@/views/MinhasFaturas')
    },
    {
        name: ROUTER_NAMES.MINHAS_INSCRICOES,
        path: '/minhas-inscricoes',
        meta: {
            menu: MENUS.AREA_CLIENTE,
            title: 'Minhas Inscrições em Campeonatos',
            searchAlias: 'Minhas Inscrições',
            searchDescription: 'Visualize as suas Inscrições',
            searchTerms: ['minhas','Inscrições'],
            hasRole: 'role/minhasInscricoes'
        },
        component: () => import('@/views/MinhasInscricoes')
    },
    
    
    
]