import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    PROFESSORES: 'Professores'
}

export default [
    {
        name: ROUTER_NAMES.PROFESSORES,
        path: '/professores',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Professores',
            searchDescription: 'Veja todas as professores cadastradas',
            searchTerms: ['professores'],
            hasRole: 'role/professores'
        },
        component: () => import('@/views/Professors')
    },
]