<template>
<img src="../../assets/marca.png" alt="SisWinner" :style="{height}"/>
</template>

<script>
export default {
  name: "AppLogo",
  props: ['height']
}
</script>

<style scoped>

</style>