import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    FREQUENCIAS: 'Frequencias'
}

export default [
    {
        name: ROUTER_NAMES.FREQUENCIAS,
        path: '/frequencia',
        meta: {
            menu: MENUS.FUNCIONALIDAES,
            searchAlias: 'Frequencias',
            searchDescription: 'Veja todas as frequencias cadastradas',
            searchTerms: ['frequencia'],
            hasRole: 'role/frequencia'
        },
        component: () => import('@/views/Frequencias')
    },
]