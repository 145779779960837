import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    LOCACOESS: 'Locação'
}

export default [
    {
        name: ROUTER_NAMES.LOCACOESS,
        path: '/locacoes',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Tipos de Locação',
            searchAlias: 'Tipos de Locação',
            searchDescription: 'Veja todas as locações cadastradas',
            searchTerms: ['Tipos', 'Locação', 'Locações'],
            hasRole: 'role/locacoes'
        },
        component: () => import('@/views/Locacaos')
    },
]