import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    QUADRASS: 'Quadras',
    DISPONIBILIDADE_QUADRAS: 'DisponibilidadeDeQuadras'
}

export default [
    {
        name: ROUTER_NAMES.QUADRASS,
        path: '/quadras',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Quadras',
            searchDescription: 'Veja todas as quadrass cadastradas',
            searchTerms: ['quadras'],
            hasRole: 'role/quadras'
        },
        component: () => import('@/views/Quadras')
    },
    {
        name: ROUTER_NAMES.DISPONIBILIDADE_QUADRAS,
        path: '/disponibilidade/quadra',
        meta: {
            title: 'Disponibilidade de Quadras'
        },
        component: () => import('@/views/DisponibilidadeDeQuadra')
    }
]