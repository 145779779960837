import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    HORARIOSS: 'Horarios'
}

export default [
    {
        name: ROUTER_NAMES.HORARIOSS,
        path: '/horarios',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Horarios',
            searchDescription: 'Veja todas as horarioss cadastradas',
            searchTerms: ['horarios'],
            hasRole: 'role/horarios'
        },
        component: () => import('@/views/Horarios')
    },
]