import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    CURSOSS: 'Cursos'
}

export default [
    {
        name: ROUTER_NAMES.CURSOSS,
        path: '/cursos',
        meta: {
            menu: MENUS.CADASTRO,
            searchAlias: 'Cursos',
            searchDescription: 'Veja todas as cursoss cadastradas',
            searchTerms: ['cursos'],
            hasRole: 'role/cursos'
        },
        component: () => import('@/views/Cursos')
    },
]